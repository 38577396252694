import { useModelStore } from "../../../store/store";
import { Viewer } from "../../../lib"
import { useModelActions } from "../../../store/hooks/use-actions";
import { useState } from "react";
import Responsive from "../components/responsive";

const Scene : React.FC<{}> = (p) => {
  const selectionActive = useModelStore(state => state.objectSelection.active);
  const selectedPaths = useModelStore(state => state.objectSelection.selected);
  const {selectObject} = useModelActions('editor');
  const [scale, setScale] = useState<number>(1);

  const handleClick = (e : any) => {
    e.stopPropagation();

    if(selectionActive) {
      const obj = e.object;
      const path = obj?.userData?.objectPath;
      if(obj && path) {
        selectObject(path);
      }
    }
  }

  return (
    <Responsive onScaleChange={setScale}>
      <Viewer
        scale={scale}
        onClick={handleClick}
        selection={selectionActive ? selectedPaths : undefined}
      />
    </Responsive>
  );
}

export default Scene;
import React, { Suspense, useRef } from 'react'
import { Canvas } from '@react-three/fiber'
import * as THREE from 'three';
import { OrbitControls } from '@react-three/drei'
import styled from 'styled-components'
import SceneObject from './scene-object';
import WalkController from './walk-controller';
import { Asset, Interaction, ObjectPath, Vec3 } from '../model/ugla-filetype';
import Overlay from './overlay';
import { EffectComposer, Selection, Select } from '@react-three/postprocessing';
import { useViewerActions } from '../store/hooks/use-actions';
import { useCameraState, useControlState, useDefaultCamera, useInteractionsState, useLightsState, useModsState, useSceneState, useUgla3dStateManager, useVisibilityState } from '../model-state/use-model-state';
import InterfaceObject from './interface-object';
import { useViewerStore } from '../store/store';
import {Outlines} from './outline';
import LightObject from './light';

type ViewerProps = {
  onInteract ?: (interaction : Interaction<Asset>) => void;
  selection ?: ObjectPath[];
  scale ?: number;
  disableControls ?: boolean;
  outlineThickness ?: number;
  outlineColor ?: string;
  children ?: React.ReactNode;
} & Omit<React.ComponentProps<typeof Canvas>, 'children'>

export const Viewer : React.FC<ViewerProps> = (p) => {
  const {onInteract, onClick, selection, scale, disableControls, ...canvasProps} = p;
  const canvasRef = useRef<HTMLCanvasElement>(null);


  const objects = useSceneState();
  const [control, setControl] = useControlState();
  const defaultCamera = useDefaultCamera();
  const [cameraState, setCameraState] = useCameraState();
  const interactions = useInteractionsState();
  const stateManager = useUgla3dStateManager();
  const mods = useModsState();
  const visibility = useVisibilityState();
  const lights = useLightsState();

  const native3DScenes = useViewerStore(state => state.native);
  const objects2D = objects.filter(o => o.asset.type === '2D');

  const { getObjectFromPath } = useViewerActions('model');

  // Refresh the viewer when default parameters changes
  const refreshKey = control;

  const selectedObject = p.selection ?
    p.selection
      .map(objPath => getObjectFromPath(objPath)?.obj)
      .filter((o) : o is THREE.Object3D => !!o)
      .reduce((acc, o) => {
        const recurse = (obj : THREE.Object3D) : THREE.Object3D[] => {
          if(obj.type === 'Mesh') {
            return [obj];
          }
          return [...obj.children.map(recurse).flat()];
        };

        return [...acc, ...recurse(o)];
      }, [] as THREE.Object3D[]) :
    [];


  // Update camera state according to control values
  const handleCameraChange = (position : THREE.Vector3, rotation : THREE.Quaternion, fov : number) => {
    setCameraState({
      position : [position.x, position.y, position.z],
      rotation : [rotation.x, rotation.y, rotation.z, rotation.w],
      fov
    })
  }

  const handleInteraction = (interaction : Interaction<Asset>) => {
    stateManager.interact(interaction.id);
    p.onInteract?.(interaction);
  }

  return (
    <Container>
      <Canvas
        key={refreshKey}
        ref={canvasRef}
        camera={{...defaultCamera, rotation : new THREE.Euler().setFromQuaternion(new THREE.Quaternion(...defaultCamera.rotation))}}
        style={{height : '100%', width : '100%', position : 'absolute'}}
        onContextMenu={() => setControl(control === 'none' ? 'walk' : 'none')}
        {...canvasProps}
      >
        {
          /*
            Light parameters (TO BE MODIFIED)
          */
        }
        {
          Object.entries(lights).map(([id, light]) => (
            <LightObject key={id} light={light} />
          ))
        }
        {
          /*
            GLTF or GLB objects
          */
        }
        {
          Object.entries(native3DScenes).map(([id, scene]) => (
            <Suspense key={id}>
              <SceneObject
                key={id}
                id={id}
                scene={scene}
                onClick={p.onClick}
              />
            </Suspense>
          ))
        }
        {
          control === 'orbit' ?
            <OrbitControls /> :
          !control || control === 'walk' ?
            <WalkController
              canvasRef={canvasRef}
              defaultPosition={[defaultCamera.position[0], defaultCamera.position[2]]}
              height={defaultCamera.position[1]}
              onChange={handleCameraChange}
              disabled={disableControls}
            /> :
            null
        }
        <Overlay
          interactions={interactions}
          onInteraction={handleInteraction}
        />
        {
          selectedObject.length ?
          selectedObject.map(o => {
            return <Outlines key={o.id} outlinedObject={o} color={p.outlineColor || 'red'} thickness={p.outlineThickness || 5} />
          }) : null
        }
        {
          p.children
        }
      </Canvas>
      <Interface>
        {
          objects2D.map(obj => (
            <InterfaceObject
              key={obj.id}
              object={obj}
              scale={p.scale}
              mods={mods}
              visibility={visibility}
              interactions={interactions}
              onInteraction={handleInteraction}
              />
          ))
        }
      </Interface>
    </Container>
  )
}

const Container = styled.div`
  position : relative;
  height : 100%;
  width : 100%;
  user-select: none;
`

const Interface = styled.div`
  position: absolute;
  height : 100%;
  width : 100%;
  pointer-events: none;
  overflow: hidden;
  z-index: 0;
`


          // <EffectComposer multisampling={1} autoClear={false} >
          //   <Outline
          //     selection={selectedObject}
          //     visibleEdgeColor={0x4479BB}
          //     hiddenEdgeColor={0x4479BB}
          //     edgeStrength={10}
          //     // @ts-ignore
          //     edgeThickness={1}
          //     blur={true}
          //     width={1000}
          //     height={1000}
          //     blendFunction={THREE.NormalBlending}
          //   />
          // </EffectComposer> :
